import { useRef, useEffect, useState } from "react";

import AudioRecorder from "./AudioRecorder";

import { ReactComponent as Microphone } from "../assets/microphone2.svg";
import { ReactComponent as Keyboard } from "../assets/keyboard3.svg";
import { ReactComponent as Speak } from "../assets/speak.svg";
interface TextAreaProps {
  handleTextareaChange: (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => void;
  handleOnSubmit?: (text: string) => void;
  setIsLoading?: (value: boolean) => void;
  containerStyle?: React.CSSProperties;
  textAreaStyle?: React.CSSProperties;
  languageLearning?: string;
  playAudio?: () => void;
  maxLength?: number;
  send?: () => void;
  index: number;
  role?: string;
  value: string;
  mode?: string;
}

export default function TextArea({
  handleTextareaChange,
  languageLearning,
  containerStyle,
  handleOnSubmit,
  textAreaStyle,
  setIsLoading,
  playAudio,
  maxLength,
  index,
  value,
  mode,
  role,
  send,
}: TextAreaProps) {
  const [overlayVisible, setOverlayVisible] = useState(!value);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Handle ctrl + enter to send message, and enter to focus textarea input
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.shiftKey) && event.key === "Enter") {
        send?.();
      } else if (!textareaRef.current?.focus() && event.key === "Enter") {
        setOverlayVisible(false);
      }
    };

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [send]);

  useEffect(() => {
    if (!textareaRef.current) return;

    setOverlayVisible(!value);

    textareaRef.current.style.height = `auto`;
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  }, [value]);

  useEffect(() => {
    if (textareaRef.current && !overlayVisible) {
      textareaRef.current.focus();
    }
  }, [overlayVisible]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    handleTextareaChange(event, index);
  };

  return (
    <div style={{ position: "relative", ...containerStyle }}>
      {/* overlay */}
      {send && overlayVisible && (
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            display: "flex",
            bottom: "7px",
            right: 0,
            left: 0,
            top: 0,
          }}
        >
          <AudioRecorder
            languageLearning={languageLearning}
            setUserText={handleOnSubmit!}
            setIsLoading={setIsLoading!}
            mode={mode}
          />
          <button
            onClick={() => {
              setOverlayVisible(false);
            }}
          >
            <Keyboard color="white" height={38} width={38} />
          </button>
        </div>
      )}

      {/* Badge */}
      <div
        className={`badge badge-${role === "assistant" ? "assistant" : "user"}`}
      >
        {role === "assistant" ? "Aimee" : "You"}
      </div>

      {/* Actual textarea */}
      <textarea
        className={`prompt-content${playAudio ? " padded" : ""} ${role}`}
        onChange={handleChange}
        maxLength={maxLength}
        style={textAreaStyle}
        spellCheck="false"
        ref={textareaRef}
        value={value}
      />

      {/* replay audio button */}
      {playAudio ? (
        <button
          style={{
            position: "absolute",
            bottom: "12px",
            right: "24px",
          }}
          onClick={playAudio}
        >
          <Speak height={28} width={28} />
        </button>
      ) : null}

      {/* text input send or microphone button */}
      {send && !overlayVisible ? (
        <button
          style={{
            transition: "opacity 0.3s ease-in-out",
            position: "absolute",
            bottom: "10px",
            right: "10px",
          }}
          onClick={
            value
              ? send
              : () => {
                  setOverlayVisible(true);
                }
          }
        >
          {value.trim() ? "SEND" : <Microphone height={38} width={38} />}
        </button>
      ) : null}
    </div>
  );
}
