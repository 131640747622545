import React from "react";

export default function LoadingOverlay() {
  return (
    <div className="loading">
      <div className="loading-spinner">
        {/* this div is needed */}
        <div />
      </div>

      {/* <div className="loading-text">
        Please wait, this might take a while...
      </div> */}
    </div>
  );
}
