import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import firebase from "firebase/compat/app";

import StyledFirebaseAuth from "./components/StyledFirebaseAuth";
import LoadingOverlay from "./components/LoadingOverlay";
import Header from "./components/Header";
import Account from "./pages/Account";
import Privacy from "./pages/Privacy";
import Home from "./pages/Home";
import Tos from "./pages/Tos";
import { uiConfig, firebaseConfig } from "./configs";
import { UserResponse } from "./types";

import "./styles/app.css";

const app = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth(app);

export default function App() {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [data, setData] = useState<UserResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);
  const [uid, setUid] = useState<string | null>(null);

  const handleSignIn = async (user: firebase.User) => {
    const { uid, email } = user;
    setIsLoading(true);
    setEmail(email);
    setUid(uid);
    const response = await fetch("/user", {
      credentials: "same-origin",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        uid,
      }),
    });
    const data: UserResponse = await response.json();
    console.log(data);
    setData(data);
    if (data.audio) {
      const _audio = new Audio(`data:audio/mp3;base64,${data.audio}`);
      setAudio(_audio);
      _audio.play();
    }
    setIsLoading(false);
  };

  const handleSignOut = async () => {
    if (window.confirm("👉 Are you sure you want to sign out?")) {
      setIsLoading(true);
      await auth.signOut();
      setEmail(null);
      setIsLoading(false);
      window.location.pathname = "/";
    }
  };

  const handleOnSubmit = async (content: any) => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
      setAudio(null);
    }

    if (content.length > 500) {
      return window.alert(
        "❌ Input too long. Please keep it under 500 characters."
      );
    }
    setIsLoading(true);
    const response = await fetch("/chat", {
      credentials: "same-origin",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        content,
        email,
        uid,
      }),
    });
    const data = await response.json();
    console.log(data);
    setData(data);
    setIsLoading(false);

    if (data.audio) {
      const _audio = new Audio(`data:audio/mp3;base64,${data.audio}`);
      setAudio(_audio);
      _audio.play();
    }
  };

  const playAudio = async () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
      audio.play();
    }
  };

  return (
    <div className="container">
      {isLoading && <LoadingOverlay />}
      <Header />
      <Routes>
        <Route
          element={
            <Home
              handleOnSubmit={handleOnSubmit}
              handleSignIn={handleSignIn}
              isLoading={isLoading}
              playAudio={playAudio}
              email={email}
              data={data}
            />
          }
          path="/"
        />
        <Route
          path="/account"
          element={<Account handleSignOut={handleSignOut} email={email} />}
        />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/tos" element={<Tos />} />
      </Routes>

      {!email && (
        <StyledFirebaseAuth
          handleSignIn={handleSignIn}
          firebaseAuth={auth}
          uiConfig={uiConfig}
        />
      )}
    </div>
  );
}
