interface Props {
  email: string | null;
  handleSignOut: () => void;
}

export default function Account({ email, handleSignOut }: Props) {
  return (
    <main>
      <p>Account page</p>
      {email && (
        <>
          <p>Signed in as {email}</p>
          <button onClick={handleSignOut}>sign out</button>
        </>
      )}
    </main>
  );
}
