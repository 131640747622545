import { useEffect, useRef, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import firebase from "firebase/compat/app";

interface Props {
  // The Firebase UI Web UI Config object.
  // See: https://github.com/firebase/firebaseui-web#configuration
  uiConfig: auth.Config;
  // Callback that will be passed the FirebaseUi instance before it is
  // started. This allows access to certain configuration options such as
  // disableAutoSignIn().
  uiCallback?(ui: auth.AuthUI): void;
  // The Firebase App auth instance to use.
  firebaseAuth: any; // As firebaseui-web
  className?: string;
  handleSignIn: (user: firebase.User) => void;
}

const StyledFirebaseAuth = ({
  uiConfig,
  firebaseAuth,
  className,
  uiCallback,
  handleSignIn,
}: Props) => {
  const [userSignedIn, setUserSignedIn] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    if (inProgress) return;

    // Get or Create a firebaseUI instance.
    const firebaseUiWidget =
      auth.AuthUI.getInstance() || new auth.AuthUI(firebaseAuth);
    if (uiConfig.signInFlow === "popup") firebaseUiWidget.reset();

    // setPersistence(firebaseAuth, browserLocalPersistence);

    // We track the auth state to reset firebaseUi if the user signs out.
    const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user) => {
      if (!user && userSignedIn) firebaseUiWidget.reset();
      if (user?.uid) {
        setInProgress(true);
        handleSignIn(user as firebase.User);
      }
      setUserSignedIn(!!user);
    });

    // Trigger the callback if any was set.
    if (uiCallback) uiCallback(firebaseUiWidget);

    // Render the firebaseUi Widget.
    if (elementRef?.current) {
      firebaseUiWidget.start(elementRef.current, uiConfig);
    }

    return () => {
      unregisterAuthObserver();
      firebaseUiWidget.reset();
    };
  }, [
    firebaseAuth,
    handleSignIn,
    inProgress,
    uiCallback,
    uiConfig,
    userSignedIn,
  ]);

  return <div className={className} ref={elementRef} />;
};

export default StyledFirebaseAuth;
