import { useState, useEffect, useRef } from "react";
import type firebase from "firebase/compat/app";

import TextArea from "../components/TextArea";
import { UserResponse } from "../types";

interface Props {
  handleSignIn: (user: firebase.User) => void;
  handleOnSubmit: (value: string) => void;
  data: UserResponse | null;
  playAudio: () => void;
  email: string | null;
  isLoading: boolean;
}

export default function Home({
  handleOnSubmit,
  playAudio,
  isLoading,
  email,
  data,
}: Props) {
  const endOfChatRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState("");

  const { chat, languages, mode, subscribed, waitNext } = data || {};

  useEffect(() => {
    endOfChatRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [chat]);

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (data?.error) {
      alert(data.error);
    }
  }, [data?.error]);

  return (
    <main>
      <h1 className="title">Smart-Mouth</h1>
      {mode !== "lesson" && (
        <>
          <h2>
            Your tailor-made language teacher engineered especially for you
            based on your personalised assessment.
          </h2>
          <h3>From English to Spanish to Chinese to Klingon! 🖖</h3>
          <p>
            Speak or type, hear your teacher's spoken words and pronounciations.
          </p>
          <p>
            Daily, bespoke, bite-sized lessons to keep you on track to succeed!
          </p>
        </>
      )}
      <h4>{mode === "lesson" ? "Lesson" : "Assessment"}</h4>
      {email ? (
        <>
          {subscribed ? (
            <ol>
              {chat?.map(({ role, content }, index) => (
                <li key={index}>
                  <div ref={endOfChatRef} />
                  <TextArea
                    playAudio={
                      index === chat.length - 1 && role === "assistant"
                        ? playAudio
                        : undefined
                    }
                    role={role as "user" | "assistant"}
                    handleTextareaChange={() => {}}
                    value={`${content}`}
                    index={index}
                  />
                </li>
              ))}

              {/* User input textarea */}
              {!isLoading && chat?.[chat.length - 1]?.role === "assistant" && (
                <TextArea
                  send={() => {
                    handleOnSubmit(value);
                    setValue("");
                  }}
                  handleTextareaChange={handleTextareaChange}
                  languageLearning={languages?.learning}
                  handleOnSubmit={handleOnSubmit}
                  index={chat.length}
                  key={"user-input"}
                  maxLength={400}
                  value={value}
                  role="input"
                  mode={mode}
                />
              )}

              <div ref={endOfChatRef} />

              {/* Wait message when awaiting a reply */}
              {(isLoading || (mode === "lesson" && waitNext)) && (
                <div key={"waitNext"}>
                  <TextArea
                    handleTextareaChange={handleTextareaChange}
                    value={"Please wait..."}
                    role="waiting"
                    index={999}
                  />
                </div>
              )}
            </ol>
          ) : (
            <p>Join the wait list </p>
          )}
        </>
      ) : (
        <>
          <p>Not logged in</p>
        </>
      )}
    </main>
  );
}
