import firebase from "firebase/compat/app";

export const uiConfig = {
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  privacyPolicyUrl: "/privacy",
  signInSuccessUrl: "/",
  signInFlow: "popup",
  tosUrl: "/tos",
};

export const firebaseConfig = {
  appId: "1:406481998697:web:ac60dddd01b04792d7e3f8",
  apiKey: "AIzaSyD9bYLCBS7oDmV1lIsDzvYLQMilVRt3ZLs",
  authDomain: "svoxai.firebaseapp.com",
  storageBucket: "svoxai.appspot.com",
  messagingSenderId: "406481998697",
  projectId: "svoxai",
};
