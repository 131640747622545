import { useState, useRef, useEffect } from "react";

import { ReactComponent as Microphone } from "../assets/microphone2.svg";

interface Props {
  setIsLoading: (value: boolean) => void;
  setUserText: (text: string) => void;
  languageLearning?: string;
  mode?: string;
}

const beep = new Audio("beep.wav");

export default function AudioRecorder({
  languageLearning,
  setIsLoading,
  setUserText,
  mode,
}: Props) {
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const mediaStream = useRef<MediaStream | null>(null);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const chunks = useRef([]);

  const startRecording = async () => {
    beep.play();
    setRecording(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          //  @ts-ignore
          chunks.current.push(e.data);
        }
      };
      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(chunks.current, { type: "audio/webm" });
        setAudioBlob(recordedBlob);
        chunks.current = [];
      };
      mediaRecorder.current.start();
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
    setRecording(false);
    beep.play();
    setIsLoading(true);
  };

  useEffect(() => {
    if (audioBlob) {
      sendAudio();
      setAudioBlob(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioBlob]);

  const sendAudio = async () => {
    if (recording || !audioBlob) {
      return;
    }

    const formData = new FormData();
    formData.append("audio", audioBlob, "audio.wav");

    if (mode === "lesson" && languageLearning) {
      formData.append("additionalData", JSON.stringify({ languageLearning }));
    }

    const response = await fetch("/upload-audio", {
      credentials: "same-origin",
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    setAudioBlob(null);
    setUserText(data);
  };

  return (
    <button onClick={recording ? stopRecording : startRecording}>
      <Microphone fill={recording ? "green" : "white"} height={38} width={38} />
    </button>
  );
}
